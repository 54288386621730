#hero {
  height: 100%;
  width: 100vw;
  transition: background 1s ease;
}

#hero .hero-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1300px;
  margin: 0 auto;
  transition: background 1s ease;
}

#hero .hero-container .item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  transition: background 1s ease;
}

#hero .hero-container .item-container .large-heading {
  position: sticky;
  margin-top: 80px;
  font-size: 150px;
  z-index: 100;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.2;
}

#hero .hero-container .item-container .medium-heading {
  position: sticky;
  margin-top: 150px;
  font-size: 30px;
  z-index: 100;
  letter-spacing: 1rem;
  line-height: 1.7;
}

#hero .hero-container .item-container {
  position: relative;
}

/* #hero .hero-container .item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 600px;
  width: 600px;
  margin-top: 100px;
} */

#hero .hero-container .item-link {
  text-decoration: none;
  font-size: 70px;
  letter-spacing: 0.2rem;
  width: 200%;
  color: #fff;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}
