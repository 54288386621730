footer {
  height: 70vh;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  transition: background 1s ease;
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 30px;
}

footer .footer-big-heading {
  font-size: 180px;
  margin-bottom: 30px;
}

footer .footer-med-heading {
  margin-bottom: 30px;
  grid-row-start: 2;
  font-size: 38px;
  line-height: 1.6;
}

footer h2:nth-of-type(2) {
  grid-row-start: 3;
  margin-bottom: 20px;
}

footer a {
  position: relative;
  margin-bottom: 30px;
  grid-row: 4;
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

footer > a:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  top: 40px;
  left: 0;
  background: #fff;
  transition: all 1s ease;
}
footer a:hover:after {
  width: 35%;
}

footer hr {
  grid-row: 5;
  grid-column: 1 / span 6;
}

footer .social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-row: 6;
  grid-column: 1 / span 6;
  width: 100%;
  padding-bottom: 20px;
}

footer .social p {
  font-size: 20px;
}

footer .social ul {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  list-style: none;
}

footer .social ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 0.5rem 1rem;
}
