@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 90px;
  width: 100vw;
  background: rgba(0, 0, 0, 0.1);
  transition: all 1s linear;
}

.navbar-container {
  overflow: hidden;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  height: 100%;
}

.navbar-container .logo .logo-link {
  color: #fff;
  text-decoration: none;
}

.navbar-container .logo h1 {
  font-size: 36px;
  font-family: "Lato", sans-serif;
}

.navbar-container ul {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
}

.navbar-container ul li {
  margin-right: 10px;
}

.navbar-container ul li .nav-link {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 131px;
  height: 76;
}

.navbar-container ul li .nav-link .circle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  margin-top: 10px;
  transition: all 1s linear;
}

.navbar-container ul li .nav-link i {
  font-size: 10px;
}

.navbar-container ul li .nav-link .circle::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 100%;
  height: 2px;
  width: 0;
  background: #fff;
  transform: translateX(100%);
  transform: translateY(-50%);
}

.navbar-container ul li .nav-link .circle::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  background: #fff;
  right: 99%;
}

.navbar-container ul li .nav-link:hover .circle:before,
.navbar-container ul li .nav-link:hover .circle:after {
  animation: animate 1s forwards linear;
}

.navbar-container:hover ul li .nav-link .circle {
  transition: all 10s linear;
}

@keyframes animate {
  0% {
    width: 0;
  }
  100% {
    width: 50px;
  }
  100% {
    width: 50px;
  }
  0% {
    width: 0;
  }
}
/* 
@keyframes animateText {
  0% {
    display: none;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
} */


#hero {
  height: 100%;
  width: 100vw;
  transition: background 1s ease;
}

#hero .hero-container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 1300px;
  margin: 0 auto;
  transition: background 1s ease;
}

#hero .hero-container .item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  transition: background 1s ease;
}

#hero .hero-container .item-container .large-heading {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 80px;
  font-size: 150px;
  z-index: 100;
  font-weight: 300;
  letter-spacing: 0.2rem;
  line-height: 1.2;
}

#hero .hero-container .item-container .medium-heading {
  position: -webkit-sticky;
  position: sticky;
  margin-top: 150px;
  font-size: 30px;
  z-index: 100;
  letter-spacing: 1rem;
  line-height: 1.7;
}

#hero .hero-container .item-container {
  position: relative;
}

/* #hero .hero-container .item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 600px;
  width: 600px;
  margin-top: 100px;
} */

#hero .hero-container .item-link {
  text-decoration: none;
  font-size: 70px;
  letter-spacing: 0.2rem;
  width: 200%;
  color: #fff;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

footer {
  height: 70vh;
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  transition: background 1s ease;
  padding-top: 50px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 30px;
}

footer .footer-big-heading {
  font-size: 180px;
  margin-bottom: 30px;
}

footer .footer-med-heading {
  margin-bottom: 30px;
  grid-row-start: 2;
  font-size: 38px;
  line-height: 1.6;
}

footer h2:nth-of-type(2) {
  grid-row-start: 3;
  margin-bottom: 20px;
}

footer a {
  position: relative;
  margin-bottom: 30px;
  grid-row: 4;
  color: #fff;
  text-decoration: none;
  font-size: 24px;
}

footer > a:after {
  position: absolute;
  content: "";
  height: 2px;
  width: 0;
  top: 40px;
  left: 0;
  background: #fff;
  transition: all 1s ease;
}
footer a:hover:after {
  width: 35%;
}

footer hr {
  grid-row: 5;
  grid-column: 1 / span 6;
}

footer .social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-row: 6;
  grid-column: 1 / span 6;
  width: 100%;
  padding-bottom: 20px;
}

footer .social p {
  font-size: 20px;
}

footer .social ul {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  list-style: none;
}

footer .social ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 0.5rem 1rem;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Italiana", serif;
  background: #000;
  color: #fff;
  overflow-x: hidden;
}

