* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Italiana", serif;
  background: #000;
  color: #fff;
  overflow-x: hidden;
}
